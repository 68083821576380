import React, { createContext, useContext, useEffect, useState } from "react";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [csrfToken, setCsrfToken] = useState("");

  useEffect(() => {
    // Fetch CSRF token as soon as the provider mounts
    fetch(process.env.REACT_APP_DOMAIN_ADDRESS_1 + "/v1/protected", {
      credentials: "include", // Ensures cookies are included with the request
    })
      .then((response) => response.text())
      .then((token) => {
        setCsrfToken(token.replace(/^"|"$/g, ""));
      })
      .catch((error) => console.error("Error fetching CSRF token:", error));
  }, []);

  const login = () => {
    setIsAuthenticated(true);
  };

  const logout = () => {
    setIsAuthenticated(false);
    setCsrfToken(""); // Clear the CSRF token on logout
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, csrfToken }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
