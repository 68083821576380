import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import Keycloak from "keycloak-js";
import axios from "axios";
import "./Login.css";
import BolidPixel from "../images/bolidPixelSmall.png";

import { ReactComponent as Logo } from "../images/svg/logoPRzRT.svg";
import { ReactComponent as BackgroundLong } from "../images/svg/backgroundLong.svg";
import { ReactComponent as MainPhoto } from "../images/svg/mainPhoto.svg";

import PostReq from "../../fetchReq/POST";

import { useAuth } from "../../auth/authProvider";

function Login() {
  const { login, csrfToken } = useAuth();

  let navigate = useNavigate();
  const [loginError, setLoginError] = useState(false);
  const [response, setResponse] = useState({});

  //keycloak options
  let initOptions = {
    url: "https://hubtest.przracing.pl",
    realm: "master",
    clientId: "hub.przracing.pl",
  };

  const handleKeycloakLogin = async () => {
    let kc = new Keycloak(initOptions);

    kc.init({
      onLoad: "login-required", // Supported values: 'check-sso' , 'login-required'
      checkLoginIframe: true,
      pkceMethod: "S256",
    }).then(
      (auth) => {
        if (!auth) {
          window.location.reload();
        } else {
          /* Remove below logs if you are using this on production */
          console.info("Authenticated");
          console.log("auth", auth);
          console.log("Keycloak", kc);
          console.log("Access Token", kc.token);

          const httpClient = axios.create({});
          /* http client will use this header in every request it sends */
          httpClient.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${kc.token}`;

          kc.onTokenExpired = () => {
            console.log("token expired");
          };
        }
      },
      (err) => {
        /* Notify the user if necessary */
        console.error("Authentication Failed: ", err);
      }
    );
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        //.email("Email jest niepoprawny")
        .required("To pole jest wymagane"),
      password: Yup.string().required("To pole jest wymagane"),
    }),
    onSubmit: (values) => {
      // console.log(values);
      PostReq({
        data: values,
        setPostResponse: setResponse,
        setError: setLoginError,
        endpoint: "login",
        requestLogin: true,
        csrfToken: csrfToken,
      });
    },
  });

  useEffect(() => {
    //console.log("response", response);
    //console.log("loginErr", loginError);
    if (!loginError && response && response.status === 200) {
      login();
      navigate("/v1/home");
    }
  }, [loginError, response, login, navigate]);

  return (
    <div className="siteWrapper">
      <div className="contentsPlacing">
        <div className="loginWrapper">
          <div className="logoPRzRT">
            <Logo />
          </div>
          <div className="loginCredentials">
            <span className="loginText textLoginSite">Zaloguj się</span>
            {loginError && (
              <div className="fetchFailedWrapper">
                <div className="fetchFailedMessage">
                  Nieprawidłowy e-mail i/lub hasło
                </div>
              </div>
            )}
            <form
              className="loginForm"
              onSubmit={formik.handleSubmit}
              noValidate
            >
              <label className="formLabel textLoginSite">Email</label>
              <input
                className="formInput"
                type="text"
                name="email"
                placeholder="example_email@gmail.com"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="errMessage">{formik.errors.email}</div>
              ) : null}

              <label className="formLabel textLoginSite">Hasło</label>
              <div className="inputFieldWithSVG">
                <input
                  className="formInput"
                  type="password"
                  name="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                />
              </div>
              {formik.touched.password && formik.errors.password ? (
                <div className="errMessage">{formik.errors.password}</div>
              ) : null}
              <a href="/nwm" className="forgottPassword">
                Nie pamiętam hasła
              </a>
              <div className="btnWrapper">
                <button className="submitBtn" type="submit">
                  <img
                    src={BolidPixel}
                    alt="bolidPixel"
                    className="bolidPixel"
                  />
                  Zaloguj
                </button>
              </div>
            </form>
            {/* <div className="btnWrapper">
              <button
                className="submitBtn keycloak"
                onClick={() => {
                  handleKeycloakLogin();
                }}
              >
                <img src={BolidPixel} alt="bolidPixel" className="bolidPixel" />
                Zaloguj z Keycloak
              </button>
            </div> */}
          </div>
          <div className="footer">Prz Racing © Copyright 2024</div>
        </div>
        <div className="graphic">
          <BackgroundLong className="backgroundSVG" />
          <MainPhoto className="mainPhotoSVG" />
        </div>
      </div>
    </div>
  );
}
export default Login;
