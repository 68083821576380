const PostReq = async ({
  data,
  setPostResponse,
  setError,
  endpoint,
  requestLogin = false,
  csrfToken,
}) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-CSRF-Token": csrfToken,
    };

    //const credentialsOption = requestLogin ? "omit" : "include";
    const credentialsOption = "include";

    //console.log(data);
    //console.log(csrfToken);

    const response = await fetch(
      `${process.env.REACT_APP_DOMAIN_ADDRESS_1}/v1/${endpoint}`,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
        credentials: credentialsOption, // Ensure cookies are included with cross-origin requests
      }
    );

    //console.log("HTTP Status Code:", response.status);

    const body = await response.text();
    //console.log(body);

    if (response.status === 200) {
      setError(false);
      setPostResponse({
        body: body,
        status: response.status,
      });
    } else {
      setError(true);
    }
  } catch (error) {
    console.log("There was an issue with POST req: ", error);
    setError(true); // Ensure setError is called in case of an exception
  }
};
export default PostReq;
